
#ris2 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background: #80817c;
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 61.5%;
    line-height: 0;
    position: relative;
    font-size: 2vw;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
#ris2 .header {
    position: absolute;
    line-height: normal;
    background: #000;
    width: 100%;
    height: 7%;
    color: #bac8c9;
    font-size: 100%;
    font-weight: bold;
}
#ris2 .header .line {
    position: absolute;
    height: 100%;
    left: 1%;
}
#ris2 .header .course {
    position: absolute;
    height: 100%;
    left: 32.5%;
}
#ris2 .header .zone {
    position: absolute;
    height: 100%;
    left: 58.5%;
}
#ris2 .header .time {
    position: absolute;
    height: 100%;
    right: 0.5%;
}
#ris2 .stops-container {
    position: absolute;
    line-height: normal;
    background: #bac8c9;
    width: 88.5%;
    height: 84.5%;
    color: #1c374c;
    font-size: 110%;
    font-weight: bold;
    top: 7.5%;
    left: 0.25%;
}
#ris2 .stops-container .next-stop {
    position: absolute;
    top: 12%;
    left: 0%;
    width: 100%;
    height: 17%;
    background: #1c374c;
    color: #bac8c9;
}
#ris2 .stops-container .next-stop.background-red {
    background: #bc3a36;
}
#ris2 .stops-container .next-stop.background-red .stop .request-stop:before {
    color: #bc3a36;
}
#ris2 .stops-container .next-stop.background-red .time-to-departure.red, #ris2 .stops-container .next-stop.background-red .time-to-departure.green {
    color: #bac8c9;
}
#ris2 .stops-container .next-stop.background-green {
    background: #527744;
}
#ris2 .stops-container .next-stop.background-green .stop .request-stop:before {
    color: #527744;
}
#ris2 .stops-container .next-stop.background-green .time-to-departure.red, #ris2 .stops-container .next-stop.background-green .time-to-departure.green {
    color: #bac8c9;
}
#ris2 .stops-container .next-stop .time {
    position: absolute;
    top: 14%;
    left: 0.5%;
    font-weight: bold;
    font-size: 130%;
}
#ris2 .stops-container .next-stop .stop {
    position: absolute;
    top: 14%;
    left: 14.5%;
    font-weight: bold;
    font-size: 130%;
}
#ris2 .stops-container .next-stop .stop .request-stop:before {
    color: #1c374c;
    content: 'Z';
    background: #bac8c9;
    border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    display: inline-block;
    font-weight: bold;
    line-height: 1em;
    margin-left: 0.1em;
    text-align: center;
    width: 1em;
}
#ris2 .stops-container .next-stop .time-to-departure {
    position: absolute;
    top: 4%;
    right: 0.5%;
    font-weight: bold;
    font-size: 153%;
}
#ris2 .stops-container .next-stop .time-to-departure.red {
    color: #bc3a36;
}
#ris2 .stops-container .next-stop .time-to-departure.green {
    color: #527744;
}
#ris2 .stops-container .previous-stop {
    top: 1.5%;
    color: #777777;
}
#ris2 .stops-container .previous-stop .stop .request-stop:before {
    background: #777777 !important;
}
#ris2 .stops-container .future-stop, #ris2 .stops-container .previous-stop {
    position: absolute;
    width: 100%;
}
#ris2 .stops-container .future-stop .time, #ris2 .stops-container .previous-stop .time {
    position: absolute;
    left: 0.7%;
}
#ris2 .stops-container .future-stop .stop, #ris2 .stops-container .previous-stop .stop {
    position: absolute;
    left: 13.7%;
}
#ris2 .stops-container .future-stop .stop .request-stop:before, #ris2 .stops-container .previous-stop .stop .request-stop:before {
    color: #bac8c9;
    content: 'Z';
    background: #1c374c;
    border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    display: inline-block;
    font-weight: bold;
    line-height: 1em;
    margin-left: 0.1em;
    text-align: center;
    width: 1em;
}
#ris2 .stops-container .future-stop.future-stop-1st, #ris2 .stops-container .previous-stop.future-stop-1st {
    top: 31%;
}
#ris2 .stops-container .future-stop.future-stop-2nd, #ris2 .stops-container .previous-stop.future-stop-2nd {
    top: 42%;
}
#ris2 .stops-container .future-stop.future-stop-3rd, #ris2 .stops-container .previous-stop.future-stop-3rd {
    top: 53%;
}
#ris2 .stops-container .future-stop.future-stop-4th, #ris2 .stops-container .previous-stop.future-stop-4th {
    top: 65%;
}
#ris2 .stops-container .future-stop.future-stop-5th, #ris2 .stops-container .previous-stop.future-stop-5th {
    top: 76%;
}
#ris2 .stops-container .future-stop.terminus-stop, #ris2 .stops-container .previous-stop.terminus-stop {
    bottom: 12.5%;
    font-size: 120%;
}
#ris2 .stops-container .horizontal-row {
    position: absolute;
    bottom: 13.5%;
    left: 0%;
    width: 100%;
    height: 1%;
    background: #008fce;
}
#ris2 .stops-container .terminus-button {
    position: absolute;
    bottom: 1%;
    right: 0.5%;
    width: 40%;
    height: 12%;
    color: #bac8c9;
    background: #008fce;
}
#ris2 .stops-container .terminus-button .line {
    position: absolute;
    font-weight: bold;
    top: 10%;
    left: 1.5%;
}
#ris2 .stops-container .terminus-button .terminus {
    position: absolute;
    text-transform: uppercase;
    top: 30%;
    left: 14%;
    width: 85%;
    text-align: center;
    font-weight: bold;
    font-size: 53%;
    font-family: Verdana, Arial, Helvetica, sans-serif;
}
#ris2 .icons-container {
    position: absolute;
    width: 10.5%;
    height: 84%;
    top: 7.5%;
    right: 0.25%;
}
#ris2 .icons-container .icon {
    background-color: #1c374c;
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: 50%;
    width: 100%;
    height: 18%;
    margin-top: 12%;
    border: 0.05em solid #beb4b8;
}
#ris2 .icons-container .icon:first-child {
    margin-top: 0;
}
#ris2 .icons-container .icon1 {
    background-image: url('icon1.png');
}
#ris2 .icons-container .icon2 {
    background-image: url('icon2.png');
}
#ris2 .icons-container .icon3 {
    background-image: url('icon3.png');
    background-size: 45%;
}
#ris2 .icons-container .icon4 {
    background-image: url('icon4.png');
    background-size: 50%;
}
#ris2 .icons-container .icon5 {
    background-image: url('icon5.png');
}
#ris2 .footer {
    background: #32353a;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7.5%;
    padding-top: 0.3%;
}
#ris2 .footer .js-inject-switches {
    display: inline-block;
    position: relative;
    left: 8%;
    width: 30%;
    height: 100%;
}
#ris2 .footer .item {
    display: inline-block;
    color: #788190;
    height: 100%;
    padding: 0.7% 1% 0 1%;
    margin: 0 0.3%;
    position: relative;
    line-height: 1;
    border-top: 0.25em solid #788190;
}
#ris2 .footer .item.red {
    color: #bc3a36;
    border-color: #bc3a36;
}
#ris2 .footer .item.green {
    color: #527744;
    border-color: #527744;
}
#ris2 .footer .item.blue {
    color: #008fce;
    border-color: #008fce;
}
#ris2 .footer .item.no-margin-left {
    margin-left: 0;
}
#ris2 .footer .item.no-margin-right {
    margin-right: 0;
}
#ris2 .footer .item p {
    display: table-cell;
    position: relative;
    top: 15%;
    margin-bottom: 0;
    font-size: 70%;
    font-weight: bold;
    text-align: center;
}
#ris2 .footer .item p .turn-45 {
    transform: rotate(45deg);
}